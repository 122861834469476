// AdvisorsPartnersComponent.js
import React from 'react';
import * as S from './styles'; // Importing styled components

const advisorsData = [
  {
    name: 'Jeff and Maven Capital joined our Vision to create the best Multiplayer FPS shooter and advise us on the go to market strategy!',
    description: '',
    imageUrl: 'https://via.placeholder.com/150',
  },

  {
    name: 'Jorge was former director of operations over at FireStarter and oversaw all launches and incubations. He is currently over at Muse which is trailblazing AI powered tools for musicians',
    description: '',
    imageUrl: 'https://via.placeholder.com/150',
  },

  {
    name: 'Several more advisors are joining our board this month to make sure our launch is perfect',
    description: '',
    imageUrl: 'https://via.placeholder.com/150',
  },
  {
    name: 'Finalizing Market Making deal with Kairon Labs for our launch',
    description: '',
    imageUrl: 'https://via.placeholder.com/150',
  },
];

const AdvisorCard = ({ name, description, imageUrl }) => (
  <S.AdvisorCard>
    <S.AdvisorName>{name}</S.AdvisorName>
    <S.AdvisorDescription>{description}</S.AdvisorDescription>
    <S.LinkedInLink href='#'></S.LinkedInLink>
  </S.AdvisorCard>
);

const AdvisorsPartnersComponent = () => {
  return (
    <S.SectionContainer id='aboutSection'>
      <S.SectionTitle>ADVISORS AND PARTNERS</S.SectionTitle>
      <S.CardsContainer>
        {advisorsData.map((advisor, index) => (
          <AdvisorCard key={index} {...advisor} />
        ))}
      </S.CardsContainer>
    </S.SectionContainer>
  );
};

export default AdvisorsPartnersComponent;
