import React from 'react';
import AdvisorsPartnersComponent from '../../components/AdvisorsPartnersComponent';
import ContactFormComponent from '../../components/ContactFormComponent';
import HeaderSectionComponent from '../../components/HeaderSectionComponent';
import InvestmentBannerComponent from '../../components/InvestmentBannerComponent';
import InvestmentComponent from '../../components/InvestmentComponent';
import ProjectCardsComponent from '../../components/ProjectCardsComponent';
import TimelineUpdateComponent from '../../components/TimelineUpdateComponent';
const HomePage = () => {
  return (
    <>
      <InvestmentBannerComponent />
      <ProjectCardsComponent />
      <InvestmentComponent />
      <AdvisorsPartnersComponent />
      <TimelineUpdateComponent />

      <HeaderSectionComponent />
      <ContactFormComponent />
    </>
  );
};

export default HomePage;
