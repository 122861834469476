import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
   @import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');

  :root {
    --foreground-rgb: 255, 255, 255; // Assuming a light font color for better contrast with the dark background
    --background-start-rgb: 30, 15, 57; // Converted hex color #1E0F39 to RGB
    --background-end-rgb: 30, 15, 57; // Same as above for a consistent theme color

  }

  @media (prefers-color-scheme: dark) {
    :root {
      --foreground-rgb: 255, 255, 255;
      --background-start-rgb: 30, 15, 57;
      --background-end-rgb: 30, 15, 57;
    }
  }

  * {
    box-sizing: border-box;
  }

  body {
    color: rgb(var(--foreground-rgb));
    background: rgb(var(--background-start-rgb)); // Using a solid theme color for simplicity
    width: 100%;
    margin: 0;
    font-family: 'Unbounded', sans-serif; // Using Unbounded for general styles
  }

  span, p, li, a { // Assuming you meant to apply Arial to text elements like these
    font-family: 'Arial', sans-serif; // Use Arial for text elements
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Unbounded', sans-serif; // Using Unbounded for headings for consistency with the style adjustment
  }


  .-no-overflow {
    overflow: hidden;
  }
`;
