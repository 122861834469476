import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { PiWebhooksLogo } from 'react-icons/pi';
import { SiHiveBlockchain } from 'react-icons/si';

import * as S from './styles';

const InvestmentBannerComponent = ({
  subtext = 'Join the revolution today',
  imageUrl = '/assets/banner/banner-head.jpg',
  onReadMore = 'https://t.me/coins_ph_br_bot',
  onBuyNow = 'https://t.me/coins_ph_br_bot',
  onJoinTelegram = 'https://t.me/coins_ph_br_bot',
}) => {
  // Handle Read More click to open in a new tab
  const handleReadMoreClick = () => {
    if (typeof onReadMore === 'function') {
      onReadMore();
    } else if (typeof onReadMore === 'string') {
      window.open(onReadMore, '_blank');
    }
  };

  // Handle Buy Now click to open in a new tab
  const handleBuyNowClick = () => {
    if (typeof onBuyNow === 'function') {
      onBuyNow();
    } else if (typeof onBuyNow === 'string') {
      window.open(onBuyNow, '_blank');
    }
  };

  // Adjusted onClick for the Telegram link if needed
  const handleJoinTelegramClick = () => {
    if (typeof onJoinTelegram === 'function') {
      onJoinTelegram();
    } else if (typeof onJoinTelegram === 'string') {
      window.open(onJoinTelegram, '_blank');
    }
  };

  return (
    <S.BannerContainer>
      <S.BannerContent>
        <S.Heading>Invest on exciting pre-listing project</S.Heading>
        <S.ActionContainer>
          <S.ReadMoreButton onClick={handleReadMoreClick}>
            Read More About
            <PiWebhooksLogo />
          </S.ReadMoreButton>
          <S.BuyNowButton onClick={handleBuyNowClick}>
            {subtext} <SiHiveBlockchain />
          </S.BuyNowButton>

          <S.TelegramLink onClick={handleJoinTelegramClick}>
            <FaTelegramPlane />
            Acesse Grupo do Telegram
          </S.TelegramLink>
        </S.ActionContainer>
      </S.BannerContent>
      <S.BannerImage src={imageUrl} alt='Investment banner' />
    </S.BannerContainer>
  );
};

export default InvestmentBannerComponent;
