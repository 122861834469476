import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Blank({ children }) {
  const location = useLocation();

  useEffect(() => {
    // Função para rolar para o topo
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    scrollToTop();

    window.onpopstate = scrollToTop;

    return () => {
      window.onpopstate = null;
    };
  }, [location]);

  return children;
}

export default Blank;
