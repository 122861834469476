import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { MdDataSaverOn } from 'react-icons/md';
import { PiHandCoinsDuotone } from 'react-icons/pi';
import * as S from './styles';
const InvestmentComponent = () => {
  return (
    <S.Container>
      <S.HeavensLogo src='assets/homepage/havens.png' alt='Heavens Logo' />
      <S.InfoBox>
        <S.InfoText>
          <PiHandCoinsDuotone />
          Amount invested: $25,000.00
        </S.InfoText>
      </S.InfoBox>
      <S.ButtonContainer>
        <S.PreSaveButton>
          <MdDataSaverOn />
          Pré-save
        </S.PreSaveButton>
        <S.InvestNowButton>
          Invest Now <FaTelegramPlane />
        </S.InvestNowButton>
      </S.ButtonContainer>
    </S.Container>
  );
};

export default InvestmentComponent;
