import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3em;
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) =>
    props.isScrolled ? '#00000030' : 'transparent'};
  transition: all 0.3s ease;
  z-index: 10;
  backdrop-filter: ${(props) => (props.isScrolled ? 'blur(10px)' : 'none')};
  -webkit-backdrop-filter: ${(props) =>
    props.isScrolled ? 'blur(10px)' : 'none'};

  @media (max-width: 768px) {
    padding: 0 1.5em; // Reduz o padding em dispositivos menores
  }
`;

export const LogoImage = styled.img`
  height: 27px;

  @media (max-width: 768px) {
    height: 22px; // Diminui o tamanho do logo em dispositivos menores
  }
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px; // Diminui o espaço entre os itens do menu
  }
`;

export const NavItem = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  padding: 0.5em 1em;
  font-family: 'Unbounded', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px; // Reduz o tamanho da fonte dos itens do menu
  }
`;

export const BuyNowButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #41e0a3;
  backdrop-filter: blur(5.4px);
  font-family: 'Helvetica Neue';
  font-size: 16.238px;
  background-color: transparent;
  color: #41e0a3;
  padding: 5px 18px;
  line-height: normal;
  letter-spacing: 0.812px;

  svg {
    font-size: 15px;
  }

  &:hover {
    background: #41e0a3;
    color: #2e145e;
    border: 1px solid #41e0a3;
    transition: all 0.3s ease;
    scale: 1.1;
  }

  @media (max-width: 768px) {
    padding: 5px 12px; // Reduz o padding do botão
    display: none; // Esconde o botão em dispositivos menores
    font-size: 14px; // Reduz o tamanho da fonte
    gap: 5px; // Reduz o gap entre o texto e o ícone

    svg {
      font-size: 12px; // Reduz o tamanho do ícone
    }
  }
`;
