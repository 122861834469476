// HeaderSectionStyles.js
import styled from 'styled-components';

export const BlockchainSection = styled.section`
  background-image: url('assets/homepage/blockchain.jpg');
  background-size: fill;
  border-radius: 21px;
  background-position: center;
  text-align: center;
  width: 100%;
  padding: 3em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;

  min-height: 350px;
  svg {
    height: 90px;
    margin-bottom: 2em;
  }
`;

export const GamingSection = styled.section`
  background-image: url('assets/homepage/gaming.jpg');
  background-size: fill;
  border-radius: 21px;
  background-position: center;
  text-align: center;
  width: 100%;
  min-height: 350px;
  padding: 3em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  svg {
    height: 90px;
    margin-bottom: 2em;
  }
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 2rem; // Adjust as needed
  text-transform: uppercase;
  margin: 0;
`;

export const Tagline = styled.p`
  color: #ffffff;
  font-size: 1rem; // Adjust as needed
  margin: 0;
`;

// Styles for the SVG icons, if you're using them as React components
export const BlockchainIcon = styled.div`
  // Add styles for the Blockchain icon
`;

export const GameControllerIcon = styled.div`
  // Add styles for the Game controller icon
`;

// Remember to replace placeholder paths with actual paths to your background images and icon components.

export const Content = styled.div`
  display: flex;
  padding: 3em;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
