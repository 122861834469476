// TimelineUpdateStyles.js
import styled from 'styled-components';

export const UpdateSection = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 3em;
  color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;

export const Header = styled.h2`
  margin-bottom: 30px;
`;

export const Paragraph = styled.p`
  font-size: 1.8em;
  line-height: 1.2em;
  padding-right: 2em;
  margin: 0;

  @media (max-width: 768px) {
    padding-right: 0;
    font-size: 1.4em;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 1.4em;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: fit-content;
  margin-bottom: 10px;

  svg {
    margin-right: 20px;
    font-size: 1.5em;
    fill: currentColor; // Takes the color from the parent element (ListItem)
  }

  // Add additional styling
`;
