// InvestmentBannerStyles.js
import styled from 'styled-components';
export const BannerImage = styled.img`
  width: 50%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
    height: 50%;
  }
`;

export const BannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3em;
  height: 80vh;
  border-radius: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2em;
    height: 100%;
  }
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    height: 40vh;
    justify-content: flex-end;
  }
`;

export const Heading = styled.h1`
  color: #fff;
  text-transform: uppercase;
  font-size: 32px;
  padding-right: 40%;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

export const Subtext = styled.p`
  color: #fff;
  font-size: 20px;
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ReadMoreButton = styled.button`
  display: flex;
  height: 47px;
  padding: 10px 21px;
  align-items: center;
  gap: 10px;
  border-radius: 11.938px;
  border: 1px solid #a063e6;
  backdrop-filter: blur(5.5px);
  background-color: transparent;
  color: #ead8ff;
  font-family: 'Helvetica Neue';
  font-size: 16.238px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.812px;
  cursor: pointer;

  svg {
    fill: #ead8ff;
    font-size: 22px;
  }

  &:hover {
    background-color: #a063e6;
    color: #fff;
    transition: all 0.3s ease;
    scale: 1.1;
  }

  @media (max-width: 768px) {
    width: fit-content;
  }
`;

export const BuyNowButton = styled.button`
  display: flex;
  height: 47px;
  padding: 10px 21px;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  border-radius: 11.938px;
  background: #41e0a3;
  border: 1px solid #41e0a3;
  backdrop-filter: blur(5.412797451019287px);
  color: #2e145e;
  font-family: 'Helvetica Neue';
  font-size: 16.238px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.812px;

  svg {
    font-size: 22px;
  }
  &:hover {
    background-color: transparent;
    border: 1px solid #41e0a3;
    color: #41e0a3;
    transition: all 0.3s ease;
    scale: 1.1;
  }

  @media (max-width: 768px) {
    width: fit-content;
  }
`;

export const TelegramLink = styled.a`
  display: flex;
  height: 47px;
  padding: 10px 21px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 30px;
  background: var(--BLUE, linear-gradient(270deg, #2080fd 0%, #28b3f8 100%));
  backdrop-filter: blur(4.626250267028809px);
  color: #fff;
  font-family: 'Helvetica Neue';
  font-size: 13.879px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.694px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1;
  transition: all 0.3s ease;
  font-family: 'Helvetica Neue';

  &:hover {
    background: var(--BLUE, linear-gradient(270deg, #28b3f8 0%, #2080fd 100%));
    transition: all 0.3s ease;

    p {
      display: none;
    }
  }

  svg {
    fill: #fff;
    font-size: 20px;
  }
`;
