// ProjectCardsComponent.js
import React from 'react';
import { FaRegArrowAltCircleUp, FaRegMoneyBillAlt } from 'react-icons/fa'; // Placeholder for financial icons
import * as S from './styles'; // Assuming this file contains all the styled components

// Example project data extracted from the image provided
const projects = [
  {
    icon: 'assets/coins/xai.png',
    title: 'XAI',
    date: '07/12/2023',
    description:
      'Xai is a gaming-focused blockchain, built to enable open trade in the next generation of video games.',
    amountInvested: '$16,122.00',
    currentReturn: '$268,185.07',
  },
  {
    icon: 'assets/coins/OCTAVIA.png',
    title: 'OCTAVIA',
    date: '07/12/2023',
    description:
      'Octavia is your Web3 Crypto AI Assistant, powered by the VIA Token.',
    amountInvested: '$25,000.00',
    currentReturn: '$546,875.00',
  },
  {
    icon: 'assets/coins/animalia.png',
    title: 'ANIMALIA',
    date: '07/12/2023',
    description:
      'Animalia is an independent free-to-play online NFT trading card game featuring crypto-inspired meme creatures and gemstones.',
    amountInvested: '$20,000.00',
    currentReturn: '$34,375.00',
  },
  {
    icon: 'assets/coins/qorpo.png',
    title: 'QORPO',
    date: '07/12/2023',
    description:
      'QORPO World, a Web3 game developer & gaming ecosystem, is dedicated to pioneering a sustainable token model through the introduction of the QORPO token.',
    amountInvested: '$50,000.00',
    currentReturn: '$850,000.00',
  },
  {
    icon: 'assets/coins/aether.png',
    title: 'Aether Games',
    date: '07/12/2023',
    description:
      'Aether Games is a transmedia development studio focused on creating immersive experiences through games, series and interactive AI technologies.',
    amountInvested: '$50,000.00',
    currentReturn: '$406,250.00',
  },
];
const ProjectCardsComponent = () => {
  return (
    <S.ProjectsSection id='projectsSection'>
      <S.SectionTitle>latest projects launched</S.SectionTitle>
      <S.ProjectCarousel>
        {projects.map((project, index) => (
          <S.ProjectCard key={index}>
            <S.CardIconWrapper>
              <img src={project.icon} alt={`${project.title} logo`} />{' '}
            </S.CardIconWrapper>
            <S.InlineInfo>
              <S.CardTitle>{project.title}</S.CardTitle>
              <S.DateStamp>{project.date}</S.DateStamp>
            </S.InlineInfo>
            <S.CardDescription>{project.description}</S.CardDescription>
            <S.FinancialDetails>
              <S.FinancialFigure>
                <FaRegMoneyBillAlt />
                Invested: {project.amountInvested}
              </S.FinancialFigure>

              <S.FinancialReturn>
                Return: {project.currentReturn} <FaRegArrowAltCircleUp />
              </S.FinancialReturn>
            </S.FinancialDetails>
          </S.ProjectCard>
        ))}
      </S.ProjectCarousel>
    </S.ProjectsSection>
  );
};

export default ProjectCardsComponent;
