import React from 'react';
import * as S from './styles';

import { IoGameController } from 'react-icons/io5';
import { SiBlockchaindotcom } from 'react-icons/si';

const HeaderSectionComponent = () => {
  return (
    <>
      <S.Content>
        <S.BlockchainSection>
          <SiBlockchaindotcom size={100} a color='#fff' />
          <S.Title>BLOCKCHAIN</S.Title>
          <S.Tagline>
            Launching a 0 click Blockchain integration with our partners
            Stardust. Finalizing our flagship deal with SKALE network.
          </S.Tagline>
        </S.BlockchainSection>
        <S.GamingSection>
          <IoGameController size={100} color='#fff' />
          <S.Title>GAMING</S.Title>
          <S.Tagline>
            Fully new in-game UI. All game assets and textures weight decrease.
          </S.Tagline>
        </S.GamingSection>
      </S.Content>
    </>
  );
};

export default HeaderSectionComponent;
