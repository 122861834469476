import styled from 'styled-components';

export const ProjectsSection = styled.section`
  padding: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 768px) {
    padding: 2em;
  }
`;

export const ProjectCarousel = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 1em;

  &::-webkit-scrollbar {
    display: none;
  }

  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1em;
  }
`;

export const ProjectCard = styled.div`
  display: flex;
  width: 100%;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  border-radius: 26.744px;

  background: #ffffff10;
  background-blend-mode: overlay;
  backdrop-filter: blur(32px);
  justify-content: space-between;
`;

export const CardIconWrapper = styled.div`
  // Add styles for the icon wrapper if needed
`;

export const CardIcon = styled.div`
  // Style for your CardIcon, you can replace this with your SVG
  width: 50px;
  height: 50px;
  background: white; // Placeholder style
  border-radius: 50%;
`;

export const CardTitle = styled.h3``;

export const CardDescription = styled.p`
  font-size: 0.9em;
  margin: 0;
  min-height: 95px;
`;

export const FinancialDetails = styled.div`
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 1em;
  width: 100%;
`;

export const FinancialFigure = styled.div`
  display: flex;
  font-size: 12px;
  display: flex;
  text-transform: uppercase;
  width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10.698px;
  align-self: stretch;
  border-radius: 7.488px;
  background: #7149ba50;
  gap: 0.5em;
  svg {
    fill: #fff;
    font-size: 16px;
  }
`;

export const FinancialReturn = styled.div`
  display: flex;
  font-size: 12px;
  display: flex;
  text-transform: uppercase;
  width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10.698px;
  align-self: stretch;
  border-radius: 7.488px;
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
  gap: 0.5em;
`;

export const Pagination = styled.div``;

export const DateStamp = styled.div`
  color: #fff;
  font-size: 0.8em;
  text-align: center;
`;

export const InlineInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SectionTitle = styled.h2`
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
`;
