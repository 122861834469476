import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4em 3em;
  background-color: #00000030;
  color: #ffffff;
  backdrop-filter: blur(7px);
  gap: 10px;
  z-index: 100;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 4em 2em;
    bottom: 0;
  }
`;

export const HeavensLogo = styled.img`
  width: 350px; // Adjust as needed
`;

export const Header = styled.h1`
  font-size: 24px; // Adjust as needed
  font-weight: bold;
  color: #4af626; // Adjust the color based on the image
`;

export const InfoBox = styled.div`
  border: 1px solid #41d19b;
  border-radius: 120px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;

  @media (max-width: 768px) {
    margin-top: 1em;
  }
`;

export const InfoText = styled.span`
  color: #41d19b;
  font-size: 16px;
  display: flex;
  justify-content: center;

  align-items: center;
  gap: 1em;

  svg {
    font-size: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const Button = styled.button`
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 20px;
  font-size: 16px; // Adjust as needed
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
`;

export const PreSaveButton = styled(Button)`
  background-color: transparent;
  height: 47px;
  border: 1px solid #ffffff40;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  &:hover {
    background-color: #ffffff40;
  }
`;

export const InvestNowButton = styled(Button)`
  display: flex;
  height: 47px;
  padding: 10px 21px;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  border-radius: 11.938px;
  background: #41e0a3;
  border: 1px solid #41e0a3;
  backdrop-filter: blur(5.412797451019287px);
  color: #2e145e;
  font-family: 'Helvetica Neue';
  font-size: 16.238px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    font-size: 22px;
  }
  &:hover {
    background-color: transparent;
    border: 1px solid #41e0a3;
    color: #41e0a3;
    transition: all 0.3s ease;
    scale: 1.1;
  }
`;
