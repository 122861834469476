import React from 'react';
import * as S from './styles';

import { GiDeliveryDrone } from 'react-icons/gi';

import { SiGamejolt, SiJsonwebtokens } from 'react-icons/si';

const TimelineUpdateComponent = () => {
  return (
    <S.UpdateSection>
      <S.Column>
        <S.Header>LAUNCH</S.Header>
        <S.Paragraph>
          We have adjusted our launch timeline, now setting it for the end of
          April to the beginning of May, instead of the previously planned end
          of March. This decision comes as part of our commitment to ensuring
          the highest quality and readiness of our offering.
        </S.Paragraph>
      </S.Column>
      <S.Column>
        <S.Header>MARKETING</S.Header>
        <S.List>
          <S.ListItem>
            <SiGamejolt />
            Readying a full updated game that is optimized for thousands more
            players
          </S.ListItem>
          <S.ListItem>
            <SiJsonwebtokens />
            Starting our Web3 earning campaign in April
          </S.ListItem>
          <S.ListItem>
            <GiDeliveryDrone />
            Ambassador program goes live in April
          </S.ListItem>
        </S.List>
      </S.Column>
    </S.UpdateSection>
  );
};

export default TimelineUpdateComponent;
