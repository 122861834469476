// AdvisorsPartnersStyles.js
import styled from 'styled-components';

export const SectionContainer = styled.section`
  text-align: center;
  padding: 3em;
`;

export const SectionTitle = styled.h2`
  color: #fff;
  text-align: left;
  width: 100%;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const AdvisorCard = styled.div`
  border-radius: 15px;
  border: 1.201px solid rgba(255, 255, 255, 0.5);
  background-blend-mode: overlay;
  backdrop-filter: blur(8.40440845489502px);
  border: none;
  background: rgb(63, 94, 251);
  background: linear-gradient(
    90deg,
    rgb(63 94 251 / 16%) 0%,
    rgb(252 70 107 / 24%) 100%
  );
  padding: 2.3em;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export const ImageContainer = styled.div`
  // Style for image container
`;

export const AdvisorImage = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

export const AdvisorName = styled.p`
  color: #fff;
  margin: 0;
  font-weight: 600;
  font-family: 'Helveltica Neue', sans-serif;
`;

export const AdvisorDescription = styled.p`
  color: #fff;
  margin: 5px 0;
`;

export const LinkedInLink = styled.a``;
