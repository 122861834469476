import { Helmet } from 'react-helmet';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Header from './components/Header';
import { routes } from './routes';

function AppContent() {
  const location = useLocation(); // Now it's used within a Router context

  return (
    <>
      <Routes>
        {routes.map((route, index) => {
          const Template = route.template;
          const Page = route.page;
          const title = route.title;

          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Template isPublic={route.isPublic}>
                  <Helmet title={`PMZ Capital Partners - ${title}`} />
                  <Header />
                  <Page />
                </Template>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
