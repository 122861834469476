// ContactFormComponent.js
import React from 'react';
import { AiOutlineMail, AiOutlineSearch, AiOutlineSend } from 'react-icons/ai';
import { MdSubject } from 'react-icons/md';
import { RiMessage2Line } from 'react-icons/ri';
import * as S from './styles'; // Importing styled components

const ContactFormComponent = () => {
  return (
    <>
      <S.ContactSection>
        <S.ContactHeader>CONTACT OUR TEAM</S.ContactHeader>
        <S.Form>
          <S.FormGroup>
            <AiOutlineSearch />
            <S.Input type='text' placeholder='Name' />
          </S.FormGroup>
          <S.FormGroup>
            <AiOutlineMail />
            <S.Input type='email' placeholder='E-mail' />
          </S.FormGroup>
          <S.FormGroup>
            <MdSubject />

            <S.Input type='text' placeholder='Subject' />
          </S.FormGroup>
          <S.FormGroup>
            <RiMessage2Line />
            <S.TextArea placeholder='Message'></S.TextArea>
          </S.FormGroup>
          <S.SendButton>
            Send message
            <AiOutlineSend />
          </S.SendButton>
        </S.Form>
      </S.ContactSection>
      <S.Footer>
        PMZ Capital Partners © 2024. Todos direitos reservados.
      </S.Footer>
    </>
  );
};

export default ContactFormComponent;
