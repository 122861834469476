import React, { useEffect, useRef, useState } from 'react';
import { SiHiveBlockchain } from 'react-icons/si';
import { Link } from 'react-scroll';
import * as S from './styles';
const logo = 'assets/header/logo.svg';

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== 'undefined') {
        const currentScrollY = window.scrollY;
        setIsScrolled(currentScrollY > 50);
        lastScrollY.current = currentScrollY;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <S.HeaderContainer isScrolled={isScrolled}>
      <S.LogoImage src={logo} alt='Logo' />

      <S.Navigation>
        {/* Update NavItem to use Link from react-scroll for smooth scrolling */}
        <Link to='aboutSection' smooth={true} duration={500} offset={-70}>
          <S.NavItem>About</S.NavItem>
        </Link>
        <Link to='projectsSection' smooth={true} duration={500} offset={-70}>
          <S.NavItem>Projects</S.NavItem>
        </Link>
      </S.Navigation>

      <S.BuyNowButton
        onClick={() => window.open('https://t.me/coins_ph_br_bot', '_blank')}
      >
        Join the revolution today
        <SiHiveBlockchain />
      </S.BuyNowButton>
    </S.HeaderContainer>
  );
}
