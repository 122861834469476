import BlankTemplate from '../templates/Blank';

import homepage from '../pages/homepage';

export const routes = [
  // BUYING FLUX

  {
    path: '/',
    page: homepage,
    title: 'Masterclass audiovisual para Música',
    isPublic: true,
    template: BlankTemplate,
  },
];
