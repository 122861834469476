// ContactFormStyles.js
import styled from 'styled-components';

export const ContactSection = styled.section`
  padding: 3em;
  text-align: center;
`;

export const ContactHeader = styled.h2`
  color: #fff; // Adjust as needed
  margin-bottom: 30px;
`;

export const Form = styled.form`
  width: 30%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FormGroup = styled.div`
  background: #fff; // Replace with the actual color
  border-radius: 12px; // Adjust as needed
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-family: 'Helvetica Neue';

  svg {
    font-size: 20px;
    color: #000; // Adjust as needed
    margin-left: 0px;
  }
`;

export const Input = styled.input`
  background: transparent;
  border: none;
  color: #fff; // Adjust as needed
  margin-left: 10px;
  width: 100%;
  height: 50px; // Adjust as needed
`;

export const TextArea = styled.textarea`
  background: transparent;
  border: none;
  color: #151515; // Adjust as needed
  width: 100%;
  font-family: 'Helvetica Neue';
  padding: 1em;
  height: 50px; // Adjust as needed
`;

export const SendButton = styled.button`
  background: #41e0a3;
  color: #05271a;
  height: 50px;
  font-size: 0.9em;
  padding: 10px 20px;
  gap: 1em;
  border-radius: 15px;
  border: none;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 20px;
  }

  &:hover {
    background: transparent;
    color: #41e0a3;
    border: 1px solid #41e0a3;
    transition: all 0.3s ease;
  }
`;

export const Footer = styled.footer`
  color: #fff; // Adjust as needed
  height: 80px;
  background-color: #00000020; // Adjust as needed
  display: flex;
  width: 100%;
  font-size: 0.8em;
  justify-content: center;
  text-align: center;
  align-items: center;
`;
